<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->

        <ProjectInfo />

        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <BodyHeader />
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        <div class="grid" style="padding-top: 20px; justify-content: center">
          <div
            class="col-12 xl:col-12 sm:col-12 md:col-12 .col-12"
            style="text-align: center"
          >
            <div>
              <div
                class="grid"
                style="
                  box-sizing: border-box;
                  background: rgb(255, 255, 255);
                  border: 2px solid rgb(245, 246, 247);
                  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
                  border-radius: 18px;
                  justify-content: center;
                "
              >
                <div class="col-12"></div>
                <div
                  class="col-12 flex"
                  style="justify-content: center; align-items: center"
                >
                  <Image
                    src="/images/locIcon.svg"
                    alt="Image"
                    width="30"
                    style="padding-right: 10px; padding-top: 10px"
                  />
                  <h5
                    style="
                      font-style: normal;
                      font-weight: 600;
                      font-size: 15px;
                      line-height: 18px;
                      color: #466107;
                      display: flex;
                      align-items: center;
                      padding-right: 20px;
                    "
                  >
                    Todas as Áreas
                  </h5>
                  <Image
                    src="/images/logoVeg.svg"
                    alt="Image"
                    width="30"
                    style="padding-right: 10px; padding-top: 10px"
                  />
                  <h5
                    style="
                      font-style: normal;
                      font-weight: 600;
                      font-size: 15px;
                      line-height: 18px;
                      color: #466107;
                      display: flex;
                      align-items: center;
                    "
                  >
                    Vegetativo
                  </h5>
                </div>
                <div
                  class="col-12 xl:col-12 md:col-12 lg:col-12 sm:col-12 .col-12 .col-sm-12"
                  style=""
                >
                  <DataTable editMode="row" :value="products" responsiveLayout="scroll">
                    <template #header>
                      <div class="table-header">
                        <span style="padding-right: 40px">Visão Geral</span>
                        <span style="padding-right: 60px">Área 1</span>
                        <span style="padding-left: 60px">Área 2</span>
                        <span style="padding-left: 100px">Área 3</span>
                        <Button icon="pi pi-refresh" />
                      </div>
                    </template>
                    <Column field="composed" header="Compostos"></Column>
                    <Column field="area1.control" header="Controle"></Column>
                    <Column
                      field="area1.treatment"
                      header="Tratamento"
                    ></Column>
                    <Column field="area2.control" header="Controle"></Column>
                    <Column
                      field="area2.treatment"
                      header="Tratamento"
                    ></Column>
                    <Column field="area3.control" header="Controle"></Column>
                    <Column
                      field="area3.treatment"
                      header="Tratamento"
                    ></Column>
                    <template #footer> Resultados por áreas </template>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
          <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectInfo from "@/components/ProjectInfo.vue";
import BodyHeader from "@/components/Attachments/BodyHeader.vue";
import { useProjectsStore } from "@/store/projects";

export default {
  data() {
    return {
      projectsStore: useProjectsStore(),
      selectedType: null,
      loading: true,
      displayModal: false,
      filterarea: "AllAreas",
      filterType: "Controle",
      filterType2: "Vegetativo",
      products: null,

      // ###################################
    };
  },
  computed: {
    lastYearTotal() {
      let total = 0;
      for (let sale of this.sales) {
        total += sale.lastYearProfit;
      }

      return this.formatCurrency(total);
    },
    thisYearTotal() {
      let total = 0;
      for (let sale of this.sales) {
        total += sale.thisYearProfit;
      }

      return this.formatCurrency(total);
    },
    project() {
      var projectId = this.$route.params.projectId;
      return this.projectsStore.project(projectId);
    },
  },
  mounted() {
    this.verifyData();
  },
  beforeUnmount() {},
  created() {
    this.products = [
      {
        id: "1",
        composed: "Nitrogênio",
        area1: { control: "7,5 ± 0,5", treatment: "7,5 ± 0,5" },
        area2: { control: "7,5 ± 0,5", treatment: "7,5 ± 0,5" },
        area3: { control: "7,5 ± 0,5", treatment: "7,5 ± 0,5" },
      },
      {
        id: "2",
        composed: "Fósforo",
        area1: { control: "7,5 ± 0,5", treatment: "7,5 ± 0,5" },
        area2: { control: "7,5 ± 0,5", treatment: "7,5 ± 0,5" },
        area3: { control: "7,5 ± 0,5", treatment: "7,5 ± 0,5" },
      },
      {
        id: "3",
        composed: "Potássio",
        area1: { control: "7,5 ± 0,5", treatment: "7,5 ± 0,5" },
        area2: { control: "7,5 ± 0,5", treatment: "7,5 ± 0,5" },
        area3: { control: "7,5 ± 0,5", treatment: "7,5 ± 0,5" },
      },
      {
        id: "4",
        composed: "Cálcio",
        area1: { control: "7,5 ± 0,5", treatment: "7,5 ± 0,5" },
        area2: { control: "7,5 ± 0,5", treatment: "7,5 ± 0,5" },
        area3: { control: "7,5 ± 0,5", treatment: "7,5 ± 0,5" },
      },
    ];
  },
  methods: {
    verifyData() {
      if (this.project.typeProject != "01") {
        this.stackedData = this.stackedData2;
      } else {
        return this.changes;
      }
    },
    openModal() {
      this.displayModal = true;
    },
    closeModal() {
      this.displayModal = false;
    },
  },
  components: {
    ProjectInfo: ProjectInfo,
    BodyHeader: BodyHeader,
  },
};
</script>

<style>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-datatable .p-datatable-header {
  background: #90a956;
  color: #ffff;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 700;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 2px 0;
  font-weight: 600;
  color: #fff;
  background: #90a956;
  transition: box-shadow 0.15s;
}
.p-button {
  color: #000000;
  background: #ffffff;
  border: 1px solid #90a956;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}

.p-datatable .p-datatable-footer {
  background: #90a956;
  color: #ffffff;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 700;
}
</style>
